import axios from 'axios'
import axiosInstance from "./api";
import axiosInstanceTwo from "./api_2";
import TokenService from "./token.service";
const setup = (store) => {
    axiosInstance.interceptors.request.use(
        (config) => {

            return TokenService.getLocalAccessToken().then(token => {
                config.headers["x-access-token"] = token;
                config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
                return config;
            })
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    axiosInstance.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            const originalConfig = err.config;
            if (originalConfig.url !== "/oauth/token" && err.response) {
                // Access Token was expired
                if (err.response.status === 401 && !originalConfig._retry) {
                    originalConfig._retry = true;
                    try {
                        const rs = await axios.post("process.env.VUE_APP_BASEURL/oauth/token", {
                            refreshToken: TokenService.getLocalRefreshToken(),
                        });
                        const accessToken = rs.data.access_token;
                        store.dispatch('auth/refreshToken', accessToken);
                        TokenService.updateLocalAccessToken(accessToken);
                        return axiosInstance(originalConfig);
                    } catch (_error) {
                        return Promise.reject(_error);
                    }
                }
            }
            return Promise.reject(err);
        }
    );

    axiosInstanceTwo.interceptors.request.use(
        (config) => {

            return TokenService.getLocalAccessToken().then(token => {
                config.headers["x-access-token"] = token;
                config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
                return config;
            })
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    axiosInstanceTwo.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            const originalConfig = err.config;
            if (originalConfig.url !== "/oauth/token" && err.response) {
                // Access Token was expired
                if (err.response.status === 401 && !originalConfig._retry) {
                    originalConfig._retry = true;
                    try {
                        const rs = await axios.post("process.env.VUE_APP_BASEURL/oauth/token", {
                            refreshToken: TokenService.getLocalRefreshToken(),
                        });
                        const accessToken = rs.data.access_token;
                        store.dispatch('auth/refreshToken', accessToken);
                        TokenService.updateLocalAccessToken(accessToken);
                        return axiosInstance(originalConfig);
                    } catch (_error) {
                        return Promise.reject(_error);
                    }
                }
            }
            return Promise.reject(err);
        }
    );
};
export default setup;