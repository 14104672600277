import store from '@/store'
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import axios from "../services/api";
import { nextTick } from 'vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },

  {
    path: '/shop',
    name: 'shop',
    component: () => import('../views/ShopView.vue')
  },

  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },

  {
    path: '/login',
    name: 'login',
    component: () => import('../views/SignIn.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/SignUp.vue')
  },

  {
    path: '/account',
    name: 'account',
    component: () => import('../views/MyAccount.vue')
  },

  {
    path: '/instant-sell',
    name: 'instant-sell',
    component: () => import('../views/InstantSell.vue')
  },

  {
    path: '/buyer-request',
    name: 'buyer-request',
    component: () => import('../views/AddBuyerRequest.vue')
  },

  {
    path: '/buyer-requests',
    name: 'buyer-requests',
    component: () => import('../views/BuyerRequests.vue')
  },

  {
    path: '/faqs',
    name: 'faqs',
    component: () => import('../views/FaqsView.vue')
  },

  {
    path: '/my-requests',
    name: 'my-requests',
    component: () => import('../views/MyRequests.vue')
  },

  {
    path: '/business/:id',
    name: 'business',
    component: () => import('../views/MyBusiness.vue')
  },

  {
    path: '/request/:id',
    name: 'request',
    component: () => import('../views/SingleRequest.vue')
  },

  {
    path: '/categories/:slug',
    name: 'categories',
    component: () => import('../views/ProductCategories.vue')
  },

  {
    path: '/category-requests/:slug',
    name: 'category-requests',
    component: () => import('../views/CategoryBuyerRequests.vue')
  },

  {
    path: '/products/:id',
    name: 'products',
    component: () => import('../views/ProductView.vue')
  },

  {
    path: '/make-request-quotation/:id',
    name: 'make-request-quotation',
    component: () => import('../views/RequestQuotations.vue')
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = [
    'login', 'register', 'home', 'shop', 'products', 'categories', 'buyer-requests', 'category-requests',
    'faqs', 'request'
  ];

  const authPages = [
    'login', 'register',
  ]

  const authRequired = !publicPages.includes(to.name);
  const noAuth = authPages.includes(to.name);
  const loggedIn = store.state.auth.user

  if (noAuth && loggedIn) {
    next('/account')
  } else {
    next();
  }


  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router
