<template>
  <top-banners />
  <listed-products />
</template>

<script>
import ListedProducts from "../components/Home/ListedProducts.vue";
import TopBanners from "../components/Home/TopBanners.vue";

export default {
  name: "HomeView",

  components: {
    TopBanners,
    ListedProducts,
  },
};
</script>
