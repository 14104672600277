import store from '@/store'
import Localbase from 'localbase'

const db = new Localbase('db')

class TokenService {
    async getLocalRefreshToken() {
        // return await db.collection('user').limit(1).get().then(users => {
        //     const user = Object.values(users)[0]
        //     return user?.refreshToken;
        // })
        // const user = JSON.parse(localStorage.getItem("user"));
        const user = await store.state.auth.user 
        
        return user?.refreshToken
    }
    async getLocalAccessToken() {
        const user = store.state.auth.user
        return user?.token
    }
    updateLocalAccessToken(token) {
        db.collection('user').limit(1).get().then(users => {
            let user = Object.values(users)[0]
            user.token = token
            db.collection('user').doc({ id: user.id }).set(JSON.parse(JSON.stringify(user)))
        })
    }
    getUser() {
        return db.collection('user').limit(1).get().then(users => {
            const user = Object.values(users)[0]
            return user
        })
    }
    async setUser(user) {
        await db.collection('user').add(JSON.parse(JSON.stringify(user)))
    }
    removeUser() {
        db.collection('user').delete()
    }
}
export default new TokenService();
