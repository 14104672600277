import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vSelect from "../plugins/vue-select-3/src/index";
import { loadProgressBar } from './progressbar'

import setupInterceptors from './services/setupInterceptors';

window.$ = window.jQuery = require('jquery');
window.toastr = require('toastr')
window.Swal = require('sweetalert2')

require("./ltr/vendor/countdown/countdown.min");
require('./ltr/vendor/bootstrap/popper.min');
require("./ltr/vendor/bootstrap/bootstrap.min")
require("./ltr/vendor/niceselect/nice-select.min");
require("./ltr/vendor/slickslider/slick.min");
require("./ltr/vendor/venobox/venobox.min");
require("./ltr/js/nice-select");
require("./ltr/js/countdown");
require("./ltr/js/accordion");
require("./ltr/js/venobox");
require("./ltr/js/slick");
require("./ltr/js/main");

setupInterceptors(store);
loadProgressBar()

createApp(App)
    .use(store)
    .use(router)
    .component('v-select', vSelect)
    .mount('#app');
