import axios from "axios";
import TokenService from "./token.service";

const token = TokenService.getLocalAccessToken()

const instance = axios.create({
    baseURL: `${process.env.VUE_APP_BASEURL}/api/`,
    headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token, 
        "TenantId": process.env.VUE_APP_TENANTID
    },
});
export default instance;