import api from "./api";
import axios from 'axios'
import TokenService from "./token.service";
class AuthService {
    login({ email, password }) {
        return axios
            .post(`${process.env.VUE_APP_BASEURL}/oauth/token`, {
                username: email,
                password,
                grant_type: "password",
                client_id: process.env.VUE_APP_CLIENT_ID,
                client_secret: process.env.VUE_APP_CLIENT_SECRET,
            })
            .then((response) => {
                let token = response.data.access_token

                return axios.get(`${process.env.VUE_APP_BASEURL}/api/app/${process.env.VUE_APP_TENANTID}/user`, {
                    headers: {
                        Authorization: "Bearer " + token
                    }
                }).then(r => {
                    let user = r.data.user

                    user.token = token
                    user.refreshToken = response.data.refresh_token

                    TokenService.setUser(user)

                    return user
                }).catch(() => {
                    toastr.error("Account not found.", "Kindly create account to proceed.")
                })
            }).catch(() => {
                toastr.error("Login failed", "Please check your credentials.")
            });
    }
    logout() {
        TokenService.removeUser();
    }
    register({ name, email, password, password_confirmation }) {
        return api.post(`app/${process.env.VUE_APP_TENANTID}/register`, {
            name,
            email,
            password,
            password_confirmation,
            tenant_id: process.env.VUE_APP_TENANTID
        })
            .then(() => {
                window.location.href = "/login";
                toastr.success('Successfully created account. Login to proceed.')
            })
            .catch(e => {
                toastr.error('Registration Failed', e.response.data.message)
            });
    }
}
export default new AuthService();