<template>
  <router-link class="product-add btn-primary" to="/login" v-if="!currentUser">
    <i class="fas fa-phone-alt"></i><span>Show contacts</span>
  </router-link>

  <button
    class="btn btn-primary"
    v-if="currentUser"
    data-bs-toggle="collapse"
    :data-bs-target="`#${view}contactInfo${pro.guid}`"
    @click="getLead()"
  >
    <i class="fas fa-phone-alt"></i><span>Show contacts</span>
  </button>

  <div
    class="collapse border m-2 rounded"
    :id="`${view}contactInfo${pro.guid}`"
  >
    <h5 class="m-2">
      Phone:
      <a :href="`tel:${pro.business ? pro.business.phone : ''}`">
        <span class="badge bg-light text-dark">
          {{ pro.business ? pro.business.phone : "" }}
        </span>
      </a>
    </h5>
  </div>
</template>

<script>
export default {
  props: ["pro", "view"],

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },

  methods: {
    getLead() {
      console.log("current user:" + this.currentUser.name);
    },
  },
};
</script>
