<template>
  <div class="backdrop"></div>
  <a class="backtop fas fa-arrow-up" href="#"></a>

  <header class="header-part" id="main-section">
    <div class="container">
      <div class="header-content">
        <div class="header-media-group">
          <button class="header-user">
            <img src="../ltr/images/user.png" alt="user" v-if="!currentUser" />
            <img
              src="../ltr/images/user.png"
              alt="user"
              v-if="currentUser && currentUser.image === null"
            />
            <img
              :src="link + currentUser.image"
              alt="user"
              v-if="currentUser !== null && currentUser.image !== null"
            />
          </button>
          <router-link to="/">
            <img :src="app.logo" alt="logo" class="rounded" />
          </router-link>
          <button class="header-src"><i class="fas fa-search"></i></button>
        </div>

        <router-link to="/" class="header-logo">
          <img
            :src="app.logo"
            class="rounded"
            alt="logo"
            style="height: 65px"
          />
        </router-link>

        <!-- </router-link> -->

        <form class="header-form">
          <input type="text" placeholder="Search anything..." /><button>
            <i class="fas fa-search"></i>
          </button>
        </form>
        <div class="header-widget-group">
          <router-link
            to="/instant-sell"
            class="header-widget"
            title="Begin selling in minutes"
          >
            <i class="fa fa-coins"></i>
            <span>Sell</span>
            <!-- <sup>0</sup> -->
          </router-link>

          <router-link
            to="/buyer-request"
            class="header-widget mx-3"
            title="Request for quotations"
          >
            <i class="fa fa-hand-holding-heart"></i>
            <!-- <sup>0</sup> -->
            <span>Request</span>
          </router-link>

          <div class="header-widget-group">
            <router-link
              to="/login"
              class="header-widget"
              title="Login"
              v-if="!currentUser"
            >
              <i class="fa fa-sign-in-alt fa-2x"></i><span>Login</span>
            </router-link>

            <router-link
              to="/register"
              class="header-widget"
              title="Login"
              v-if="!currentUser"
            >
              <i class="fa fa-user-plus fa-2x"></i><span>Sign up</span>
            </router-link>

            <div class="dropdown" v-if="currentUser">
              <router-link
                to="javascript:void(0)"
                class="header-widget dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  :src="`${link}${currentUser.image}`"
                  alt="user"
                  style="height: 25px; width: 25px"
                  v-if="currentUser.image !== null"
                />

                <img
                  src="../ltr/images/user.png"
                  alt="user"
                  style="height: 25px; width: 25px"
                  v-if="currentUser.image === null"
                />

                <span>
                  {{ currentUser.name }}
                </span>
              </router-link>

              <ul class="dropdown-menu dropdown-menu-end mt-2 p-0">
                <li class="p-0 m-0">
                  <router-link class="dropdown-item" to="/account">
                    <i class="fa fa-user-circle"></i> Profile
                  </router-link>
                </li>

                <!-- <li class="p-0 m-0"><hr class="dropdown-divider" /></li> -->

                <li class="p-0 m-0">
                  <router-link
                    class="dropdown-item"
                    to="javascript:void(0)"
                    @click.prevent="logOut"
                  >
                    <i class="fa fa-sign-out-alt"></i> Logout
                  </router-link>
                </li>
              </ul>
            </div>
          </div>

          <!-- <button class="header-widget header-cart" title="Cartlist">
						<i class="fas fa-shopping-basket"></i>
						<sup>9+</sup>
						<span>total price<small>$345.00</small></span>
					</button> -->
        </div>
      </div>
    </div>
  </header>
  <nav class="navbar-part">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="navbar-content">
            <ul class="navbar-list m-0 p-0">
              <li class="navbar-item">
                <router-link class="navbar-link" to="/"> home </router-link>
              </li>

              <li class="navbar-item dropdown">
                <router-link to="/shop" class="navbar-link dropdown-arrow">
                  Our Products
                </router-link>
                <ul class="dropdown-position-list">
                  <li v-for="(cat, c) in categories" :key="c">
                    <router-link :to="`/categories/${cat.slug ?? ''}`">
                      {{ cat.name }}
                    </router-link>
                  </li>
                </ul>
              </li>

              <li class="navbar-item dropdown">
                <router-link to="/buyer-requests" class="navbar-link dropdown-arrow">
                  Buyer requests
                </router-link>
                <ul class="dropdown-position-list">
                  <li v-for="(cat, c) in categories" :key="c">
                    <router-link :to="`/category-requests/${cat.slug ?? ''}`">
                      {{ cat.name }}
                    </router-link>
                  </li>
                  <hr />
                  <li>
                    <router-link to="/my-requests"> My requests </router-link>
                  </li>
                </ul>
              </li>

              <li class="navbar-item">
                <router-link class="navbar-link" to="/faqs"> FAQs </router-link>
              </li>
            </ul>

            <div
              class="navbar-info-group"
              v-if="app !== undefined && app.addresses !== undefined"
            >
              <div class="navbar-info">
                <i class="icofont-ui-touch-phone"></i>
                <p class="m-0">
                  <small>call us</small>
                  <span> {{ phone.value ?? "" }} </span>
                </p>
              </div>
              <div class="navbar-info">
                <i class="icofont-ui-email"></i>
                <p class="m-0">
                  <small>e-mail us</small>
                  <span> {{ email.value ?? "" }} </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <aside class="category-sidebar">
    <div class="category-header">
      <h4 class="category-title">
        <i class="fas fa-list-ul"></i>
        <span>categories</span>
      </h4>
      <button class="category-close"><i class="icofont-close"></i></button>
    </div>
    <ul class="category-list">
      <li class="category-item" v-for="(cat, c) in categories" :key="c">
        <router-link
          class="category-link"
          :to="`/categories/${cat.slug ?? ''}`"
        >
          <img
            :src="cat.pivot.image"
            alt=""
            style="height: 1.5rem; width: 2rem"
            class="img-fluid rounded mx-2"
          />
          <span> {{ cat.name }} </span>
        </router-link>
      </li>
    </ul>
    <div class="category-footer">
      <p>
        All Rights Reserved by
        <a href="https://smartify.africa" _target="blank">Smartify Africa</a>
      </p>
    </div>
  </aside>

  <aside class="cart-sidebar">
    <div class="cart-header">
      <div class="cart-total">
        <i class="fas fa-shopping-basket"></i><span>total item (5)</span>
      </div>
      <button class="cart-close"><i class="icofont-close"></i></button>
    </div>
    <ul class="cart-list">
      <li class="cart-item">
        <div class="cart-media">
          <a href="#"
            ><img src="../ltr/images/product/01.jpg" alt="product" /></a
          ><button class="cart-delete"><i class="far fa-trash-alt"></i></button>
        </div>
        <div class="cart-info-group">
          <div class="cart-info">
            <h6><a href="product-single.html">existing product name</a></h6>
            <p>Unit Price - $8.75</p>
          </div>
          <div class="cart-action-group">
            <div class="product-action">
              <button class="action-minus" title="Quantity Minus">
                <i class="icofont-minus"></i></button
              ><input
                class="action-input"
                title="Quantity Number"
                type="text"
                name="quantity"
                value="1"
              /><button class="action-plus" title="Quantity Plus">
                <i class="icofont-plus"></i>
              </button>
            </div>
            <h6>$56.98</h6>
          </div>
        </div>
      </li>
      <li class="cart-item">
        <div class="cart-media">
          <a href="#"
            ><img src="../ltr/images/product/02.jpg" alt="product" /></a
          ><button class="cart-delete"><i class="far fa-trash-alt"></i></button>
        </div>
        <div class="cart-info-group">
          <div class="cart-info">
            <h6><a href="product-single.html">existing product name</a></h6>
            <p>Unit Price - $8.75</p>
          </div>
          <div class="cart-action-group">
            <div class="product-action">
              <button class="action-minus" title="Quantity Minus">
                <i class="icofont-minus"></i></button
              ><input
                class="action-input"
                title="Quantity Number"
                type="text"
                name="quantity"
                value="1"
              /><button class="action-plus" title="Quantity Plus">
                <i class="icofont-plus"></i>
              </button>
            </div>
            <h6>$56.98</h6>
          </div>
        </div>
      </li>
      <li class="cart-item">
        <div class="cart-media">
          <a href="#"
            ><img src="../ltr/images/product/03.jpg" alt="product" /></a
          ><button class="cart-delete"><i class="far fa-trash-alt"></i></button>
        </div>
        <div class="cart-info-group">
          <div class="cart-info">
            <h6><a href="product-single.html">existing product name</a></h6>
            <p>Unit Price - $8.75</p>
          </div>
          <div class="cart-action-group">
            <div class="product-action">
              <button class="action-minus" title="Quantity Minus">
                <i class="icofont-minus"></i></button
              ><input
                class="action-input"
                title="Quantity Number"
                type="text"
                name="quantity"
                value="1"
              /><button class="action-plus" title="Quantity Plus">
                <i class="icofont-plus"></i>
              </button>
            </div>
            <h6>$56.98</h6>
          </div>
        </div>
      </li>
      <li class="cart-item">
        <div class="cart-media">
          <a href="#"
            ><img src="../ltr/images/product/04.jpg" alt="product" /></a
          ><button class="cart-delete"><i class="far fa-trash-alt"></i></button>
        </div>
        <div class="cart-info-group">
          <div class="cart-info">
            <h6><a href="product-single.html">existing product name</a></h6>
            <p>Unit Price - $8.75</p>
          </div>
          <div class="cart-action-group">
            <div class="product-action">
              <button class="action-minus" title="Quantity Minus">
                <i class="icofont-minus"></i></button
              ><input
                class="action-input"
                title="Quantity Number"
                type="text"
                name="quantity"
                value="1"
              /><button class="action-plus" title="Quantity Plus">
                <i class="icofont-plus"></i>
              </button>
            </div>
            <h6>$56.98</h6>
          </div>
        </div>
      </li>
      <li class="cart-item">
        <div class="cart-media">
          <a href="#"
            ><img src="../ltr/images/product/05.jpg" alt="product" /></a
          ><button class="cart-delete"><i class="far fa-trash-alt"></i></button>
        </div>
        <div class="cart-info-group">
          <div class="cart-info">
            <h6><a href="product-single.html">existing product name</a></h6>
            <p>Unit Price - $8.75</p>
          </div>
          <div class="cart-action-group">
            <div class="product-action">
              <button class="action-minus" title="Quantity Minus">
                <i class="icofont-minus"></i></button
              ><input
                class="action-input"
                title="Quantity Number"
                type="text"
                name="quantity"
                value="1"
              /><button class="action-plus" title="Quantity Plus">
                <i class="icofont-plus"></i>
              </button>
            </div>
            <h6>$56.98</h6>
          </div>
        </div>
      </li>
    </ul>
    <div class="cart-footer">
      <button class="coupon-btn">Do you have a coupon code?</button>
      <form class="coupon-form">
        <input type="text" placeholder="Enter your coupon code" /><button
          type="submit"
        >
          <span>apply</span>
        </button>
      </form>
      <a class="cart-checkout-btn" href="checkout.html"
        ><span class="checkout-label">Proceed to Checkout</span
        ><span class="checkout-price">$369.78</span></a
      >
    </div>
  </aside>

  <aside class="nav-sidebar">
    <div class="nav-header">
      <a href="#"><img :src="app.logo" alt="logo" /></a
      ><button class="nav-close"><i class="icofont-close"></i></button>
    </div>
    <div class="nav-content">
      <div class="nav-btn">
        <router-link to="/login" class="btn btn-inline" v-if="!currentUser">
          <i class="fa fa-unlock-alt"></i><span>join here</span>
        </router-link>

        <router-link to="/account" class="btn btn-inline" v-if="currentUser">
          <i class="fa fa-user-tie"></i><span> {{ currentUser.name }} </span>
        </router-link>
      </div>
      <ul class="nav-list">
        <li>
          <router-link class="nav-link" to="/">
            <i class="icofont-home"></i>Home
          </router-link>
        </li>

        <li>
          <router-link class="nav-link" to="/shop">
            <i class="fa fa-store-alt"></i>Our Products
          </router-link>
        </li>
      </ul>
      <div class="nav-info-group">
        <div class="nav-info">
          <i class="icofont-ui-touch-phone"></i>
          <p>
            <small>call us</small>
            <span> {{ phone.value ?? "" }} </span>
          </p>
        </div>
        <div class="nav-info">
          <i class="icofont-ui-email"></i>
          <p>
            <small>e-mail us</small>
            <span> {{ email.value ?? "" }} </span>
          </p>
        </div>
      </div>
      <div class="nav-footer">
        <p>All Rights Reserved by <a href="#">Smartify Africa</a></p>
      </div>
    </div>
  </aside>

  <div class="mobile-menu">
    <router-link to="/" title="Home Page">
      <i class="fa fa-home"></i><span>Home</span>
    </router-link>

    <button class="cate-btn" title="Category List">
      <i class="fas fa-list"></i><span>category</span>
    </button>

    <button class="cart-btn" title="Cartlist">
      <i class="fas fa-shopping-basket"></i>
      <span>cartlist</span><sup>9+</sup>
    </button>

    <a href="wishlist.html" title="Wishlist">
      <i class="fas fa-heart"></i><span>wishlist</span>
      <sup>0</sup>
    </a>

    <router-link to="/account" title="Compare List">
      <i class="fa fa-user-circle"></i>
      <span>Account</span>
    </router-link>
  </div>
</template>

<script>
import axios from "../services/api";
import EventBus from "../common/EventBus";

export default {
  props: ["app"],

  data() {
    return {
      products: {},
      categories: {},
      currency: {},
    };
  },

  computed: {
    currentUser() {
      let user = this.$store.state.auth.user;

      if (user === undefined) {
        this.logOut();
        return null
      } 

      return this.$store.state.auth.user;
    },

    link() {
      return process.env.VUE_APP_BASEURL;
    },

    phone() {
      if (this.app === undefined || this.app.addresses === undefined) {
        return {};
      }

      return Object.values(this.app.addresses).filter((address) => {
        if (address.main && address.type === "phone") {
          return address;
        }
      })[0];
    },

    email() {
      if (this.app === undefined || this.app.addresses === undefined) {
        return {};
      }

      return Object.values(this.app.addresses).filter((address) => {
        if (address.main && address.type === "email") {
          return address;
        }
      })[0];
    },

    app_id() {
      return process.env.VUE_APP_TENANTID;
    },
  },

  created() {
    this.getData();
  },

  mounted() {
    EventBus.on("logout", () => {
      this.logOut();
    });
  },

  beforeUnmount() {
    EventBus.remove("logout");
  },

  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },

    async getData(link = null) {
      if (link === null) {
        link = `/app/${this.app_id}/product-list`;
      }

      await axios.get(link).then((r) => {
        this.products = r.data.products;
        this.currency = r.data.currency;
        this.categories = r.data.categories;
      });
    },
  },
};
</script>
