<template>
  <section class="section suggest-part">
    <div class="container">
      <ul class="suggest-slider slider-arrow">
        <li v-for="(cat, c) in categories" :key="c">
          <router-link
            class="suggest-card"
            :to="`/categories/${cat.slug ?? ''}`"
          >
            <img :src="cat.pivot ? cat.pivot.image : ''" :alt="cat.name" />
            <h5>
              {{ cat.name }} <span> {{ cat.products_count }} items</span>
            </h5>
          </router-link>
        </li>
      </ul>
    </div>
  </section>

  <section class="section feature-part">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-heading">
            <h2>Listed products</h2>
          </div>
        </div>
      </div>

      <div class="row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5">
        <div class="col" v-for="(pro, p) in products.data" :key="p">
          <div class="product-card shadow">
            <div class="product-media">
              <div class="product-label">
                <label class="label-text new">new</label>
              </div>
              <button class="product-wish wish">
                <i class="fas fa-heart"></i>
              </button>
              <a class="product-image" :href="`/products/${pro.guid}`">
                <img
                  :src="getImage(pro)"
                  alt="product"
                  class="grid-product-image"
                />
              </a>
              <div class="product-widget">
                <a
                  title="Product details"
                  :href="`/products/${pro.guid}`"
                  class="fas fa-info-circle"
                >
                </a>
                <a
                  title="Product Video"
                  href="https://youtu.be/9xzcVxSBbG8"
                  class="venobox fas fa-play"
                  data-autoplay="true"
                  data-vbtype="video"
                >
                </a>
                <a
                  title="Product View"
                  href="#"
                  class="fas fa-eye"
                  data-bs-toggle="modal"
                  :data-bs-target="`#product-view${pro.id}`"
                ></a>
              </div>
            </div>
            <div class="product-content">
              <div class="product-rating">
                <i class="active icofont-star"></i
                ><i class="active icofont-star"></i
                ><i class="active icofont-star"></i
                ><i class="active icofont-star"></i><i class="icofont-star"></i>
                <a :href="`/products/${pro.guid}`">(3)</a>
              </div>
              <h6 class="product-name">
                <a :href="`/products/${pro.guid}`"> {{ pro.name }} </a>
              </h6>
              <h6 class="product-price">
                <span>
                  {{
                    parseFloat(pro.price).toLocaleString(
                      `en-${currency.country.code}`,
                      {
                        style: "currency",
                        currency: currency.code,
                      }
                    )
                  }}
                  <small> per {{ pro.unit ? pro.unit.short_name : "" }} </small>
                </span>
              </h6>

              <show-contact :pro="pro" view="grid" />
            </div>
          </div>

          <div class="modal fade" :id="`product-view${pro.id}`">
            <div class="modal-dialog modal-xl">
              <div class="modal-content">
                <button
                  class="modal-close icofont-close"
                  data-bs-dismiss="modal"
                ></button>
                <div class="product-view">
                  <div class="row">
                    <div class="col-md-6 col-lg-6">
                      <img
                        :src="getImage(pro)"
                        alt="product"
                        class="img-fluid p-2"
                      />
                    </div>
                    <div class="col-md-6 col-lg-6">
                      <div class="view-details">
                        <h3 class="view-name">
                          <router-link :to="`/products/${pro.guid}`">
                            {{ pro.name }}
                          </router-link>
                        </h3>
                        <div class="view-meta">
                          <p>
                            Category:<a href="#">
                              {{ pro.category ? pro.category.name : "" }}
                            </a>
                          </p>
                        </div>
                        <div class="view-rating">
                          <i class="active icofont-star"></i
                          ><i class="active icofont-star"></i
                          ><i class="active icofont-star"></i
                          ><i class="active icofont-star"></i
                          ><i class="icofont-star"></i
                          ><a :href="`/products/${pro.guid}`">(3 reviews)</a>
                        </div>
                        <h3 class="view-price">
                          <span>
                            {{
                              parseFloat(pro.price).toLocaleString(
                                `en-${currency.country.code}`,
                                {
                                  style: "currency",
                                  currency: currency.code,
                                }
                              )
                            }}
                            <small>
                              per {{ pro.unit ? pro.unit.short_name : "" }}
                            </small>
                          </span>
                        </h3>
                        <p class="view-desc">
                          {{ pro.description }}
                        </p>

                        <div class="view-add-group">
                          <show-contact :pro="pro" view="modal" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="section-btn-25">
            <router-link to="/shop" class="btn btn-primary">
              <i class="fas fa-eye"></i><span>show more</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "../../services/api";
import ShowContact from "../ShowContact.vue";

export default {
  data() {
    return {
      products: {},
      currency: {},
      categories: {},
    };
  },

  created() {
    this.getData();
  },

  components: {
    ShowContact,
  },

  computed: {
    app_id() {
      return process.env.VUE_APP_TENANTID;
    },

    currentUser() {
      return this.$store.state.auth.user;
    },
  },

  methods: {
    async getData(link = null) {
      if (link === null) {
        link = `/app/${this.app_id}/product-list`;
      }

      await axios.get(link).then((r) => {
        this.products = r.data.products;
        this.currency = r.data.currency;
        this.categories = r.data.categories;
      });

      this.setSlickTimeout();
    },

    setSlickTimeout() {
      try {
        this.initSlick();
      } catch (error) {
        window.location.reload();
      }
    },

    initSlick() {
      $(".home-index-slider").slick({
        dots: !1,
        fade: !0,
        infinite: !0,
        autoplay: !0,
        arrows: !0,
        speed: 600,
        prevArrow: '<i class="icofont-arrow-right dandik"></i>',
        nextArrow: '<i class="icofont-arrow-left bamdik"></i>',
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1200,
            settings: { slidesToShow: 1, slidesToScroll: 1 },
          },
          { breakpoint: 992, settings: { slidesToShow: 1, slidesToScroll: 1 } },
          { breakpoint: 768, settings: { slidesToShow: 1, slidesToScroll: 1 } },
          {
            breakpoint: 576,
            settings: { slidesToShow: 1, slidesToScroll: 1, arrows: !1 },
          },
        ],
      }),
        $(".home-grid-slider").slick({
          dots: !0,
          fade: !1,
          infinite: !0,
          autoplay: !0,
          arrows: !0,
          speed: 600,
          prevArrow: '<i class="icofont-arrow-right dandik"></i>',
          nextArrow: '<i class="icofont-arrow-left bamdik"></i>',
          slidesToShow: 1,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 1200,
              settings: { slidesToShow: 1, slidesToScroll: 1 },
            },
            {
              breakpoint: 992,
              settings: { slidesToShow: 1, slidesToScroll: 1 },
            },
            {
              breakpoint: 768,
              settings: { slidesToShow: 1, slidesToScroll: 1 },
            },
            {
              breakpoint: 576,
              settings: { slidesToShow: 1, slidesToScroll: 1, arrows: !1 },
            },
          ],
        }),
        $(".home-category-slider").slick({
          dots: !0,
          fade: !0,
          infinite: !0,
          autoplay: !0,
          arrows: !0,
          speed: 500,
          prevArrow: '<i class="icofont-arrow-right dandik"></i>',
          nextArrow: '<i class="icofont-arrow-left bamdik"></i>',
          slidesToShow: 1,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 1200,
              settings: { slidesToShow: 1, slidesToScroll: 1 },
            },
            {
              breakpoint: 992,
              settings: { slidesToShow: 1, slidesToScroll: 1 },
            },
            {
              breakpoint: 768,
              settings: { slidesToShow: 1, slidesToScroll: 1 },
            },
            {
              breakpoint: 576,
              settings: { slidesToShow: 1, slidesToScroll: 1, arrows: !1 },
            },
          ],
        }),
        $(".home-classic-slider").slick({
          dots: !1,
          fade: !1,
          infinite: !0,
          autoplay: !0,
          arrows: !0,
          speed: 800,
          prevArrow: '<i class="icofont-arrow-right dandik"></i>',
          nextArrow: '<i class="icofont-arrow-left bamdik"></i>',
          slidesToShow: 1,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 1200,
              settings: { slidesToShow: 1, slidesToScroll: 1 },
            },
            {
              breakpoint: 992,
              settings: { slidesToShow: 1, slidesToScroll: 1 },
            },
            {
              breakpoint: 768,
              settings: { slidesToShow: 1, slidesToScroll: 1 },
            },
            {
              breakpoint: 576,
              settings: { slidesToShow: 1, slidesToScroll: 1, arrows: !1 },
            },
          ],
        }),
        $(".suggest-slider").slick({
          dots: !1,
          infinite: !0,
          autoplay: !0,
          arrows: !0,
          speed: 1e3,
          prevArrow: '<i class="icofont-arrow-right dandik"></i>',
          nextArrow: '<i class="icofont-arrow-left bamdik"></i>',
          slidesToShow: 5,
          slidesToScroll: 2,
          responsive: [
            {
              breakpoint: 1200,
              settings: { slidesToShow: 4, slidesToScroll: 4 },
            },
            {
              breakpoint: 992,
              settings: { slidesToShow: 3, slidesToScroll: 3 },
            },
            {
              breakpoint: 768,
              settings: { slidesToShow: 2, slidesToScroll: 2 },
            },
            {
              breakpoint: 576,
              settings: { slidesToShow: 2, slidesToScroll: 2, arrows: !1 },
            },
          ],
        }),
        $(".new-slider").slick({
          dots: !1,
          infinite: !0,
          autoplay: !0,
          arrows: !0,
          speed: 800,
          prevArrow: '<i class="icofont-arrow-right dandik"></i>',
          nextArrow: '<i class="icofont-arrow-left bamdik"></i>',
          slidesToShow: 5,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 1200,
              settings: { slidesToShow: 4, slidesToScroll: 2 },
            },
            {
              breakpoint: 992,
              settings: { slidesToShow: 3, slidesToScroll: 3 },
            },
            {
              breakpoint: 768,
              settings: { slidesToShow: 2, slidesToScroll: 2 },
            },
            {
              breakpoint: 576,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                variableWidth: !0,
                arrows: !1,
              },
            },
          ],
        }),
        $(".category-slider").slick({
          dots: !1,
          infinite: !0,
          autoplay: !0,
          arrows: !0,
          speed: 800,
          prevArrow: '<i class="icofont-arrow-right dandik"></i>',
          nextArrow: '<i class="icofont-arrow-left bamdik"></i>',
          slidesToShow: 5,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 1200,
              settings: { slidesToShow: 5, slidesToScroll: 5 },
            },
            {
              breakpoint: 992,
              settings: { slidesToShow: 4, slidesToScroll: 4 },
            },
            {
              breakpoint: 768,
              settings: { slidesToShow: 3, slidesToScroll: 3 },
            },
            {
              breakpoint: 576,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                variableWidth: !0,
                arrows: !1,
              },
            },
          ],
        }),
        $(".brand-slider").slick({
          dots: !1,
          infinite: !0,
          autoplay: !1,
          arrows: !0,
          speed: 800,
          prevArrow: '<i class="icofont-arrow-right dandik"></i>',
          nextArrow: '<i class="icofont-arrow-left bamdik"></i>',
          slidesToShow: 5,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 1200,
              settings: { slidesToShow: 4, slidesToScroll: 4 },
            },
            {
              breakpoint: 992,
              settings: { slidesToShow: 3, slidesToScroll: 3 },
            },
            {
              breakpoint: 768,
              settings: { slidesToShow: 2, slidesToScroll: 2 },
            },
            {
              breakpoint: 576,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                variableWidth: !0,
                arrows: !1,
              },
            },
          ],
        }),
        $(".blog-slider").slick({
          dots: !1,
          infinite: !0,
          autoplay: !1,
          arrows: !0,
          speed: 800,
          prevArrow: '<i class="icofont-arrow-right dandik"></i>',
          nextArrow: '<i class="icofont-arrow-left bamdik"></i>',
          slidesToShow: 3,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 1200,
              settings: { slidesToShow: 3, slidesToScroll: 3 },
            },
            {
              breakpoint: 992,
              settings: { slidesToShow: 2, slidesToScroll: 2 },
            },
            {
              breakpoint: 768,
              settings: { slidesToShow: 1, slidesToScroll: 1 },
            },
            {
              breakpoint: 576,
              settings: { slidesToShow: 1, slidesToScroll: 1, arrows: !1 },
            },
          ],
        }),
        $(".testimonial-slider").slick({
          dots: !1,
          infinite: !0,
          autoplay: !1,
          arrows: !0,
          fade: !1,
          speed: 1e3,
          centerMode: !0,
          centerPadding: "250px",
          slidesToShow: 1,
          slidesToScroll: 1,
          prevArrow: '<i class="icofont-arrow-right dandik"></i>',
          nextArrow: '<i class="icofont-arrow-left bamdik"></i>',
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerPadding: "250px",
              },
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerPadding: "130px",
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerPadding: "40px",
              },
            },
            {
              breakpoint: 576,
              settings: {
                arrows: !1,
                slidesToShow: 1,
                slidesToScroll: 1,
                centerPadding: "10px",
              },
            },
          ],
        }),
        $(".testi-slider").slick({
          dots: !1,
          infinite: !0,
          autoplay: !1,
          arrows: !0,
          speed: 800,
          prevArrow: '<i class="icofont-arrow-right dandik"></i>',
          nextArrow: '<i class="icofont-arrow-left bamdik"></i>',
          slidesToShow: 1,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 1200,
              settings: { slidesToShow: 1, slidesToScroll: 1 },
            },
            {
              breakpoint: 992,
              settings: { slidesToShow: 1, slidesToScroll: 1 },
            },
            {
              breakpoint: 768,
              settings: { slidesToShow: 1, slidesToScroll: 1 },
            },
            {
              breakpoint: 576,
              settings: { slidesToShow: 1, slidesToScroll: 1 },
            },
          ],
        }),
        $(".team-slider").slick({
          dots: !1,
          infinite: !0,
          autoplay: !1,
          arrows: !0,
          speed: 800,
          prevArrow: '<i class="icofont-arrow-right dandik"></i>',
          nextArrow: '<i class="icofont-arrow-left bamdik"></i>',
          slidesToShow: 4,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 1200,
              settings: { slidesToShow: 3, slidesToScroll: 3 },
            },
            {
              breakpoint: 992,
              settings: { slidesToShow: 3, slidesToScroll: 3 },
            },
            {
              breakpoint: 768,
              settings: { slidesToShow: 2, slidesToScroll: 2 },
            },
            {
              breakpoint: 576,
              settings: { slidesToShow: 1, slidesToScroll: 1 },
            },
          ],
        }),
        $(".isotope-slider").slick({
          dots: !1,
          infinite: !0,
          autoplay: !1,
          arrows: !0,
          speed: 800,
          prevArrow: '<i class="icofont-arrow-right dandik"></i>',
          nextArrow: '<i class="icofont-arrow-left bamdik"></i>',
          slidesToShow: 5,
          slidesToScroll: 2,
          responsive: [
            {
              breakpoint: 1200,
              settings: { slidesToShow: 5, slidesToScroll: 2 },
            },
            {
              breakpoint: 992,
              settings: { slidesToShow: 3, slidesToScroll: 3 },
            },
            {
              breakpoint: 768,
              settings: { slidesToShow: 2, slidesToScroll: 2 },
            },
            {
              breakpoint: 576,
              settings: { slidesToShow: 2, slidesToScroll: 2 },
            },
          ],
        }),
        $(".preview-slider").slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: !0,
          fade: !0,
          asNavFor: ".thumb-slider",
          prevArrow: '<i class="icofont-arrow-right dandik"></i>',
          nextArrow: '<i class="icofont-arrow-left bamdik"></i>',
          responsive: [
            {
              breakpoint: 576,
              settings: { slidesToShow: 1, slidesToScroll: 1, arrows: !0 },
            },
          ],
        }),
        $(".thumb-slider").slick({
          slidesToShow: 3,
          slidesToScroll: 1,
          asNavFor: ".preview-slider",
          dots: !1,
          arrows: !1,
          centerMode: !0,
          focusOnSelect: !0,
          responsive: [
            {
              breakpoint: 992,
              settings: { slidesToShow: 3, slidesToScroll: 1 },
            },
            {
              breakpoint: 768,
              settings: { slidesToShow: 3, slidesToScroll: 1 },
            },
            {
              breakpoint: 576,
              settings: { slidesToShow: 3, slidesToScroll: 1 },
            },
            {
              breakpoint: 400,
              settings: { slidesToShow: 2, slidesToScroll: 1 },
            },
          ],
        }),
        $(".details-preview").slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: !1,
          fade: !0,
          asNavFor: ".details-thumb",
          prevArrow: '<i class="icofont-arrow-right dandik"></i>',
          nextArrow: '<i class="icofont-arrow-left bamdik"></i>',
          responsive: [
            {
              breakpoint: 576,
              settings: { slidesToShow: 1, slidesToScroll: 1, arrows: !0 },
            },
          ],
        }),
        $(".details-thumb").slick({
          slidesToShow: 5,
          slidesToScroll: 1,
          asNavFor: ".details-preview",
          dots: !1,
          arrows: !1,
          focusOnSelect: !0,
          responsive: [
            {
              breakpoint: 1200,
              settings: { slidesToShow: 3, slidesToScroll: 1 },
            },
            {
              breakpoint: 992,
              settings: { slidesToShow: 5, slidesToScroll: 1 },
            },
            {
              breakpoint: 768,
              settings: { slidesToShow: 4, slidesToScroll: 1 },
            },
            {
              breakpoint: 576,
              settings: { slidesToShow: 4, slidesToScroll: 1, vertical: !1 },
            },
            {
              breakpoint: 400,
              settings: { slidesToShow: 3, slidesToScroll: 1, vertical: !1 },
            },
          ],
        });
    },

    getImage(pro) {
      let images = Object.values(pro.images);

      let def = images.filter((img) => img.default === true);
      if (def.length > 0) {
        return def.link;
      }

      if (images.length > 0) {
        return images[0].link;
      }
    },
  },
};
</script>
