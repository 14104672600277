import axios from "axios";
import TokenService from "./token.service";

const token = TokenService.getLocalAccessToken()

const instance = axios.create({
    baseURL: `${process.env.VUE_APP_BASEURL}/api/app/${process.env.VUE_APP_TENANTID}/`,
    headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
    },
});
export default instance;