<template>
  <section class="home-index-slider slider-arrow slider-dots">
    <div v-for="(banner, b) in banners" :key="b">
      <div class="banner-part banner-1" v-if="banner.orientation === 'ltr'">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-6 col-lg-6">
              <div class="banner-content">
                <h1>{{ banner.heading }}</h1>
                <p v-html="banner.content"></p>
                <div class="banner-btn">
                  <a
                    class="btn btn-outline"
                    :href="link.link"
                    v-for="(link, l) in banner.links"
                    :key="l"
                  >
                    <i :class="link.icon"></i>
                    <span> {{ link.label }} </span>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="banner-img">
                <img :src="banner.image" alt="index" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="banner-part banner-2" v-if="banner.orientation === 'rtl'">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-6 col-lg-6">
              <div class="banner-img">
                <img :src="banner.image" alt="index" />
              </div>
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="banner-content">
                <h1>{{ banner.heading }}</h1>
                <p v-html="banner.content"></p>
                <div class="banner-btn">
                  <a
                    class="btn btn-outline"
                    :href="link.link"
                    v-for="(link, l) in banner.links"
                    :key="l"
                  >
                    <i :class="link.icon"></i>
                    <span> {{ link.label }} </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "../../services/api";
export default {
  data() {
    return {
      banners: {},
    };
  },

  computed: {
    app_id() {
      return process.env.VUE_APP_TENANTID;
    },
  },

  created() {
    this.getData();
  },

  methods: {
    getData() {
      axios.get(`/app/${this.app_id}/banners`).then((r) => {
        this.banners = r.data.banners;
      });
    },
  },
};
</script>
